"use strict";
import Splide from '@splidejs/splide';


export class HowWeWork {
  constructor() {
    

    const configSlider = {
        perPage: 3,
        drag: false,
        breakpoints: {
            1025 : { 
                perPage: 1,
                drag : true,
                type : 'loop',
            }
        }
    }


    const slider = new Splide( '.howWeWork__splide', configSlider ).mount();
  }
}
