"use strict";

export class Footer {
  constructor() {
    //
    const footerBTN = document.getElementsByClassName("j-footerBtn")[0];

    if (footerBTN) {
      footerBTN.addEventListener("click", () => {
        footerBTN.closest(".footer__galapagos").classList.toggle("footer--open");
      });
    }
  }
}