// Main javascript entry point
// Should handle bootstrapping/starting application

'use strict';

import $ from 'jquery';
import 'core-js';
import 'regenerator-runtime/runtime';
import 'pagepiling.js'
import { HowWeWork } from "../_modules/how-we-work/how-we-work";
import { Menu } from "../_modules/menu/menu";
import { Footer } from "../_modules/footer/footer";
import { ChangeFramework } from "../_modules/change-framework/change-framework";
import { WhatWeOffer } from "../_modules/what-we-offer/what-we-offer";

$(() => {
  new Menu();
  new Footer();
  new HowWeWork();
  new ChangeFramework();
  new WhatWeOffer();
});
