"use strict";
import Splide from '@splidejs/splide';


export class ChangeFramework {
  constructor() {
    

    const configSliderDesktop = {
      perPage: 1,
      drag: true,
      type: 'fade',
    }

    const configSliderMobile = {
      perPage: 1,
      drag: true,
      type: 'loop',
    }

    const splide = new Splide('.changeFramework__splide', window.innerWidth < 1025 ? configSliderMobile : configSliderDesktop).mount();
  }
}
