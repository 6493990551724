"use strict";

export class Menu {
  constructor() {
    //
    const menu = document.getElementsByClassName("j-menu")[0];

    if (menu) {
      menu.addEventListener("click", () => {
        menu.classList.toggle("menu--open");
      });
    }
  }
}
